/**
 * getTourPrice to show for city tour card
 * @param {Object} priceMap
 * @param {Array} currency
 * @returns
 */
export const getTourPrice = (priceMap, currency) => {
  if (!priceMap?.anchorPrice) {
    return null
  }
  if (priceMap.anchorPrice === -1 || !priceMap.currencies) {
    return null
  }
  const currencyData = priceMap.currencies.find(c => c.id === currency)
  const roundUpPrice = getDisplayPrice(priceMap.anchorPrice * currencyData.rate)
  if (currencyData) {
    return addCurrencySymbol(currency, roundUpPrice)
  }
}

/**
 * getDiscountedPrice to show for city tour card
 * @param {Object} priceMap
 * @param {Array} currency
 * @returns
 */
export const getDiscountedTourPrice = (priceMap, currency, showDecimal) => {
  if (!priceMap?.discountedPrice) {
    return null
  }
  if (priceMap.discountedPrice === -1 || !priceMap.currencies) {
    return null
  }
  const currencyData = priceMap.currencies.find(c => c.id === currency)
  const priceOfChosenCurrency = priceMap.discountedPrice * currencyData.rate
  let roundUpPrice = getDisplayPrice(priceOfChosenCurrency)
  if (showDecimal) {
    roundUpPrice = getDisplayPriceDecimal(priceOfChosenCurrency)
  }
  if (currencyData) {
    return addCurrencySymbol(currency, roundUpPrice)
  }
}

export const getTourPriceDecimal = (priceMap, currency) => {
  if (!priceMap?.anchorPrice) {
    return null
  }

  if (priceMap.anchorPrice === -1 || !priceMap.currencies) {
    return null
  }

  const currencyData = priceMap.currencies.find(c => c.id === currency)
  const roundUpPrice = getDisplayPriceDecimal(
    priceMap.anchorPrice * currencyData.rate
  )

  if (currencyData) {
    return addCurrencySymbol(currency, roundUpPrice)
  }
}

const getDisplayPriceDecimal = (price) => {
  const p = Number(price)

  if (Number(p.toFixed(0)) === Number(p.toFixed(2))) {
    return p.toFixed(0)
  }

  return p.toFixed(2)
}

export const getTourPriceNum = (priceMap, currency, decimal = true) => {
  if (!priceMap?.anchorPrice || !priceMap.currencies) {
    return null
  }
  const currencyData = priceMap.currencies.find(c => c.id === currency)
  const priceOfChosenCurrency = priceMap.anchorPrice * currencyData.rate

  if (decimal) {
    return Number(priceOfChosenCurrency).toFixed(2)
  }

  return getDisplayPriceDecimal(priceOfChosenCurrency)
}

// Deprecated, use getPriceLabel instead
export const getDetailPrice = (tour, currency) => {
  const priceMap = tour.priceMap

  if (!priceMap.anchorPrice) {
    return null
  }

  if (priceMap.anchorPrice === -1 || !priceMap.currencies) {
    return null
  }

  const currencyData = priceMap.currencies.find(c => c.id === currency)

  if (!currencyData) {
    return null
  }

  const roundUpPrice = getDisplayPrice(priceMap.anchorPrice * currencyData.rate)
  return addCurrencySymbol(currency, roundUpPrice)
}

export const getDetailDiscountedPrice = (tour, currency) => {
  const priceMap = tour.priceMap
  if (!priceMap.discountedPrice) {
    return null
  }
  if (priceMap.discountedPrice === -1 || !priceMap.currencies) {
    return null
  }
  const currencyData = priceMap.currencies.find(c => c.id === currency)
  const priceOfCurrency = priceMap.discountedPrice * currencyData.rate
  const roundUpPrice = getDisplayPrice(priceOfCurrency)

  if (currencyData) {
    return addCurrencySymbol(currency, roundUpPrice)
  }
}

export const getPrefixSymbol = (curCurrency) => {
  if (curCurrency === 'AUD') {
    return 'AU'
  } else if (curCurrency === 'CAD') {
    return 'CA'
  }
  return null
}

const getDisplayPrice = (price) => {
  const p = Number(price)
  return Math.round(p)
}

export const addCurrencySymbol = (currency, priceStr) => {
  switch (currency) {
    case 'USD':
      return '$' + priceStr
    case 'EUR':
      return '€' + priceStr
    case 'GBP':
      return '£' + priceStr
    case 'CAD':
      return '$' + priceStr
    case 'AUD':
      return '$' + priceStr
  }
}

export const getDetailedPrefixSymbol = (currency) => {
  switch (currency) {
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    case 'GBP':
      return '£'
    case 'CAD':
      return 'CA$'
    case 'AUD':
      return 'AU$'
  }
}

export const addDetailedCurrencySymbol = (currency, priceStr) => {
  switch (currency) {
    case 'USD':
      return '$' + priceStr
    case 'EUR':
      return '€' + priceStr
    case 'GBP':
      return '£' + priceStr
    case 'CAD':
      return 'CA$' + priceStr
    case 'AUD':
      return 'AU$' + priceStr
  }
}

export const getCurrencyPricesFromBookings = (bookings, currency) => {
  const prices = []
  bookings.forEach((booking) => {
    booking.unitItems.forEach((unitItem) => {
      booking.option.units.forEach((unit) => {
        if (unit.id === unitItem.unitId) {
          prices.push(unit.pricing.find(i => i.currency === currency))
        }
      })
    })
  })
  return prices
}

export const getPriceLabel = (product, currency) => {
  console.log('getPriceLabel', product, currency)
  const originalPrice = getFromPrice(product, currency)
  if (!originalPrice) {
    return null
  }
  return addCurrencySymbol(currency, originalPrice)
}

export const getFromPrice = (product, currency) => {
  const option = product.options?.[0]
  if (!option) {
    return null
  }
  // console.log('units', option.units)
  const unit = option.units.find(u => u.internalName?.toLowerCase() === 'adult' 
        || u.title?.toLowerCase() === 'adult')
  if (!unit) {
    return null
  }
  const originalPrice = unit.pricingFrom.find(p => p.currency === currency)?.original
  if (!originalPrice) {
    return null
  }
  return originalPrice / 100
}

export const getDiscountedTourPriceNum = (priceMap, currency, decimal = true) => {
  if (!priceMap?.discountedPrice) {
    return null
  }
  if (priceMap.discountedPrice === -1 || !priceMap.currencies) {
    return null
  }
  const currencyData = priceMap.currencies.find(c => c.id === currency)
  const priceOfChosenCurrency = priceMap.discountedPrice * currencyData.rate
  
  let roundUpPrice = getDisplayPrice(priceOfChosenCurrency)
  if (decimal) {
    roundUpPrice = getDisplayPriceDecimal(priceOfChosenCurrency)
  }

  return roundUpPrice
}
